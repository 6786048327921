import React, { useEffect, useRef, useState } from "react";
import {
  AccountCircleOutlined,
  ArrowBack,
  AttachFile,
  KeyboardVoice,
  PhotoCamera,
  Search,
  Send,
  SentimentSatisfiedAlt,
} from "@mui/icons-material";
import {
  IconButton,
  InputAdornment,
  TextField,
  Button,
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import "./masterMindCommunity.scss";
import customAxios from "../../utils/customAxios";
import { ApiConfig, socketUrl } from "../../services/ApiConfig";
import { useNavigate, useParams } from "react-router";
import { Container, display } from "@mui/system";
import io from "socket.io-client";
import { socketEvent } from "../guestUser/guestSocketConfig/guestSocket";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import EmojiPicker from "emoji-picker-react";
import Webcam from "react-webcam";
import ScheduleCallPopUp from "../../components/scheduleAction/ScheduleCallPopUp";
import VoiceInputButton from "./VoiceInputButton";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

export default function MasterMindCommunity() {
  const scrollToBottom = useRef(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentChat, setCurrentChat] = useState(0);
  const [topicFilter, setTopicFilter] = useState("");
  const [topicList, setTopicList] = useState([]);
  const [currentTopic, setCurrentTopic] = useState();
  console.log("🚀 ~ MasterMindCommunity ~ currentTopic:", currentTopic);
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  const [isConnected, setIsConnected] = useState(false);
  const [messageList, setMessageList] = useState([]);
  console.log("🚀 ~ MasterMindCommunity ~ messageList:", messageList);
  const [scheduleModal, setScheduleModal] = useState(false);
  const [roomId, setRoomId] = useState("");
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const [message, setMessage] = useState("");
  const [BOList, setBOList] = useState("");
  const webcamRef = React.useRef(null);
  const [updateScheduleCallList, setUpdateScheduleCallList] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const [showWebcam, setShowWebcam] = useState(false);
  const [currentBO, setCurrentBO] = useState("");
  const chatScreen = useRef();
  const [isListening, setIsListening] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  console.log("🚀 ~ MasterMindCommunity ~ initialLoading:", initialLoading);
  const socketRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  console.log("🚀 ~ MasterMindCommunity ~ currentPage:", currentPage);
  const [totalPages, setTotalPages] = useState(1);
  console.log("🚀 ~ MasterMindCommunity ~ totalPages:", totalPages);
  const [isFetching, setIsFetching] = useState(false);
  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = io(socketUrl, {
        secure: true,
        reconnect: true,
        transports: ["websocket"],
      });
    }
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, []);

  const userId = sessionStorage.getItem("userId");
  const userUniqueId = sessionStorage.getItem("userUniqueId");
  useEffect(() => {
    window.sessionStorage.removeItem("roomId");
    getInitialTopics();
    getInitialBOList();

    return () => {
      disconnectSocket();
      if (socketRef.current) {
        const roomData = {
          userId: userId,
        };
        socketRef.current.disconnect(roomData);
      }
    };
  }, []);

  useEffect(() => {
    if (isConnected) {
      handleInitialSocketConnect();
    }
  }, [currentTopic]);

  useEffect(() => {
    if (isConnected) {
      receiveMessageHandler();
      userMessageHandler();
    }
  }, [isConnected]);

  useEffect(() => {
    if (socketRef.current) {
      socketRef.current.on("connect", () => {
        setIsConnected(true);
        setInitialLoading(false);
      });
      return () => {
        socketRef?.current?.off("connect");
        socketRef?.current?.off(socketEvent.initiateChat);
      };
    }
  }, [roomId]);
  const [messagesAdded, setMessagesAdded] = useState(false);
  const handleChatData = () => {
    if (socketRef.current) {
      socketRef.current.off("chatInitiated");
      socketRef.current.on("chatInitiated", (data) => {
        setInitialLoading(false);
        scrollToBottom.current = true;
        setMessageList((prevMessageList) => {
          const newMessages = data?.messages || [];
          return [...newMessages, ...prevMessageList];
        });
        setRoomId(data?.chatRoomId);
        setCurrentPage(data.currentPage);
        setTotalPages(data.totalPages);
      });
    }
  };
  const getInitialTopics = async () => {
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.getCohortWithTopicListing,
        params: {
          cohort_id: id,
        },
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
      });
      console.log("getCohortWithTopicListing-=-=-", res);
      if (!res?.data) {
        return;
      }
      setTopicList(res?.data?.result[0]?.cohortTopics || []);

      const storedRoomId = sessionStorage.getItem("roomId");
      if (storedRoomId) {
        setCurrentTopic(JSON.parse(storedRoomId));
      } else if (res?.data?.result[0]?.cohortTopics?.length > 0) {
        // setCurrentTopic(res?.data?.result[0]?.cohortTopics);
        setCurrentTopic();
      }
    } catch (err) {
      console.log(err, "Error occurred while fetching topics");
    }
  };
  const getInitialBOList = async () => {
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.getAllBOList,
        params: {
          cohort_id: id,
        },
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
      });
      if (!res?.data) {
        return;
      }
      setBOList(res?.data?.result?.BOs || []);
      if (res?.data?.result?.BOs.length > 0) {
        setCurrentBO(res?.data?.result?.BOs);
      }
    } catch (err) {
      console.log(err, "hfsf");
    }
  };
  const handleInitialSocketConnect = () => {
    if (socketRef.current) {
      const roomData = {
        userId: userId,
        RoomId: currentTopic?.id,
        userOnlineFlag: true,
        page: currentPage,
        limit: 50,
      };

      socketRef.current.emit(socketEvent.initiateChat, roomData);
      handleChatData();
    }
  };
  const roomIdRef = useRef(roomId);
  useEffect(() => {
    roomIdRef.current = roomId;
  }, [roomId]);
  const receiveMessageHandler = () => {
    if (socketRef.current) {
      socketRef.current.on("receiveMessage", (data) => {
        if (roomIdRef.current === data?.room_id) {
          setMessageList((prevMessageList) => [...prevMessageList, data]);
        }
      });
      socketRef.current.on("userMessage", (data) => {
        console.log("Received data from userMessage event", data);
        setMessageList((prevMessageList) => [...prevMessageList, data]);
      });
      socketRef.current.on("receiveMessage", () => {
        console.log("Finished receiving message");
        setIsLoading(false);
      });

      socketRef.current.on("userMessage", () => {
        console.log("Finished receiving user message");
        setIsLoading(false);
      });
    } else {
      console.error(
        "socketRef.current is null, socket might not be initialized"
      );
    }
  };
  const sendMessageHandler = async () => {
    setShowEmojiPicker(false);
    try {
      if (socketRef.current) {
        socketRef.current.emit(socketEvent.sendMessage, {
          from: userId,
          user_unique_id: userUniqueId,
          RoomId: roomId,
          message: message?.trim(),
        });
        setMessage("");
      }
    } catch (error) {
      console.log(error, "Error sending message");
    }
  };
  const userMessageHandler = async () => {
    try {
      if (socketRef.current) {
        socketRef.current.emit(socketEvent.userMessage, {
          from: userId,
          RoomId: roomId,
          message: message?.trim(),
        });
        setMessage("");
      }
    } catch (error) {
      console.log(error, "Error sending message");
    }
  };
  const handleFileSelect = (files) => {
    if (files.length > 0) {
      const selectedFile = files[0];
    }
  };
  const disconnectSocket = async () => {
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.disConnectSocket,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
      });
      if (res.data.statusCode === 200) {
        console.log(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // const handleCapturePhoto = () => {
  //   console.log("Button clicked");
  //   const imageSrc = webcamRef.current.getScreenshot();
  //   console.log(imageSrc, "imageSrc");
  //   setCapturedImage(imageSrc);
  //   setShowWebcam(false);
  // };
  const handleEmojiClick = (event, emojiObject) => {
    console.log(event, emojiObject, "aasasasas");
    if (event?.emoji) {
      setMessage((prevMessage) => prevMessage + event.emoji);
    }
  };
  const toggleEmojiPicker = () => {
    setShowEmojiPicker((prevState) => !prevState);
  };
  const handleVoiceInput = () => {
    const recognition = new window.webkitSpeechRecognition();
    recognition.continuous = false;
    recognition.lang = "en-US";

    recognition.onstart = () => {
      console.log("Voice recognition started.");
      setIsListening(true);
    };

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      console.log("Transcript:", transcript);
      setMessage(transcript);
    };

    recognition.onerror = (event) => {
      console.error("Speech recognition error:", event.error);
      setIsListening(false);
    };

    recognition.onend = () => {
      console.log("Voice recognition ended.");
      setIsListening(false);
    };

    recognition.start();
  };

  // Track scroll event
  const handleScroll = () => {
    console.log("🚀 ~ handleScroll is being call correctly");
    const chatContainer = chatScreen.current;
    if (
      chatContainer.scrollTop === 0 &&
      !isFetching &&
      currentPage < totalPages
    ) {
      setIsFetching(true);
      loadMoreMessages();
    }
  };

  const loadMoreMessages = () => {
    if (socketRef.current) {
      socketRef.current.off("chatInitiated");
      console.log("🚀 ~ loadMoreMessages is being call correctly");
      const roomData = {
        userId: sessionStorage.getItem("userId"),
        RoomId: roomId,
        page: currentPage + 1,
        limit: 50,
      };

      socketRef.current.emit(socketEvent.initiateChat, roomData);
      setIsLoadingMessages(true);
      socketRef.current.on("chatInitiated", (data) => {
        console.log("chatInitiated frm ending", data);
        const chatContainer = chatScreen.current;
        const scrollTopBefore = chatContainer.scrollTop;
        const scrollHeightBefore = chatContainer.scrollHeight;
        // Set a timeout to ensure loader is visible for at least 500ms
        setTimeout(() => {
          setMessageList((prevMessageList) => {
            const newMessages = data?.messages || [];
            return [...newMessages, ...prevMessageList];
          });
          setMessagesAdded({ scrollTopBefore, scrollHeightBefore });
          setCurrentPage(data.currentPage);
          setTotalPages(data.totalPages);
          setIsFetching(false);
          setIsLoadingMessages(false);
        }, 500);
      });
    }
  };

  useEffect(() => {
    const chatContainer = chatScreen?.current;
    if (chatContainer) {
      console.log("Inside thi chatContainer chatContainer:");
      chatContainer.addEventListener("scroll", handleScroll);
      return () => {
        chatContainer.removeEventListener("scroll", handleScroll);
      };
    }
  }, [messageList, currentPage, totalPages]);

  useEffect(() => {
    const chatContainer = chatScreen?.current;
    if (chatContainer && scrollToBottom) {
      const lastMessage = chatContainer.lastChild;
      console.log("🚀 ~ useEffect ~ lastMessage:", lastMessage);
      if (lastMessage) {
        lastMessage.scrollIntoView({ block: "end" });
      }
    }

    !scrollToBottom.current && scrollToLastMessage();
    scrollToBottom.current = false;
  }, [messageList]);

  const scrollToLastMessage = () => {
    if (messagesAdded) {
      console.log("🚀 ~ scrollToLastMessage ~ scrollToLastMessage is called :");
      const chatContainer = chatScreen.current;
      const scrollHeightAfter = chatContainer.scrollHeight;

      // Restore scroll position after messages are added
      chatContainer.scrollTop =
        scrollHeightAfter -
        messagesAdded.scrollHeightBefore +
        messagesAdded.scrollTopBefore;

      setMessagesAdded(false); // Reset flag after scroll adjustment
    }
  };

  const handleSelectTopic = (topic) => {
    setInitialLoading(true);
    setMessageList([]);
    setTotalPages(1);
    setCurrentPage(1);
    setCurrentChat(topic.id);
    setCurrentTopic(topic);
    setRoomId(topic.id);
    sessionStorage.setItem("roomId", topic.id);
    sessionStorage.setItem("currentTopic", topic?.topicName);
    sessionStorage.setItem("currentChat", topic.id);
    handleChatData();
  };
  return (
    <Box style={{ background: currentTheme == "DARK" ? "#151515" : "#f8f8f8" }}>
      <Container maxWidth="lg">
        <div
          className="masterMindCommunityContainer"
          style={{ background: currentTheme == "DARK" ? "#151515" : "#f8f8f8" }}
        >
          <div
            className="header"
            style={{ background: currentTheme == "DARK" ? "#151515" : "#fff" }}
          >
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBack
                style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              />
            </IconButton>
            <h2 style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}>
              Master Mind Community
            </h2>
          </div>

          <div className="chatContainer">
            <div
              className="chatList"
              style={{
                background: currentTheme == "DARK" ? "#000" : "#fff",
              }}
            >
              <Box className="header">
                <h3 style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}>
                  Topics
                </h3>
                <TextField
                  value={topicFilter}
                  onChange={(e) => {
                    setTopicFilter(e?.target?.value);
                  }}
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  style={{
                    backgroundColor: "#FCFCFC",
                    padding: "0",

                    background: currentTheme == "DARK" ? "#151515" : "#FCFCFC",
                  }}
                  placeholder="Search..."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <Search
                            style={{
                              color: currentTheme == "DARK" ? "#fff" : "#000",
                            }}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: {
                      padding: "0 5px",
                      color: currentTheme == "DARK" ? "#fff" : "#000",
                    },
                  }}
                />
              </Box>
              <div
                className="chats scrollBarCustomColor"
                component={OverlayScrollbarsComponent}
                style={{
                  background: currentTheme == "DARK" ? "#000" : "#f8f8f8",
                  height: "60vh",
                  overflowY: "scroll",
                }}
              >
                {topicList?.length === 0 && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: "80%",
                      textAlign: "center",
                      padding: "20px",
                      backgroundColor:
                        currentTheme === "DARK" ? "#333" : "#f5f5f5",
                      color: currentTheme === "DARK" ? "#fff" : "#000",
                      borderRadius: "8px",
                      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        color: currentTheme === "DARK" ? "#fff" : "#000",
                        fontWeight: "500",
                      }}
                    >
                      No Topic Available
                    </Typography>
                  </Box>
                )}
                {topicList &&
                  topicList?.length > 0 &&
                  topicList
                    ?.filter((e) => e?.topicName?.includes(topicFilter))
                    ?.map((topic, i) => {
                      const isDisabled =
                        sessionStorage.getItem("roomId") === topic.id;
                      return (
                        <div
                          key={i}
                          style={{
                            background:
                              currentChat !== topic.id && currentTheme == "DARK"
                                ? "#000"
                                : currentChat == topic.id
                                ? "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)"
                                : "#fff",
                            color: currentChat == topic.id && "#fff",
                          }}
                          onClick={() => {
                            if (!isDisabled) {
                              handleSelectTopic(topic);
                            }
                          }}
                        >
                          <span
                            style={{
                              background:
                                currentChat === topic.id
                                  ? "#fff"
                                  : sessionStorage.getItem("currentChat") ===
                                    topic.id
                                  ? "#fff"
                                  : "#fff",
                              color:
                                currentChat === topic.id &&
                                currentTheme == "DARK"
                                  ? "#000"
                                  : currentTheme == "DARK"
                                  ? "#000"
                                  : "#000",
                            }}
                          >
                            {topic?.topicName[0].toUpperCase()}
                          </span>
                          <p
                            style={{
                              color:
                                currentChat === topic.id ||
                                currentTheme == "DARK"
                                  ? "#fff"
                                  : currentChat === topic.id
                                  ? "#fff"
                                  : "#000",
                            }}
                            className="ellipsis"
                          >
                            {topic?.topicName}
                          </p>
                        </div>
                      );
                    })}
              </div>
            </div>
            <div className="chatScreen">
              <div
                className="header"
                style={{
                  background: currentTheme == "DARK" ? "#000" : "#fff",
                }}
              >
                <p
                  style={{
                    color: currentTheme == "DARK" ? "#fff" : "#000",
                  }}
                >
                  Community Chat
                </p>
              </div>
              {initialLoading ? (
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "51%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: "999",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <>
                  {currentChat === 0 && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "52%",
                        left: "52%",
                        transform: "translate(-50%, -50%)",
                        textAlign: "center",
                        padding: "20px",
                        color: currentTheme === "DARK" ? "#fff" : "#000",
                        borderRadius: "8px",
                      }}
                    >
                      Please select a topic to start conversation!
                    </Box>
                  )}

                  {currentChat !== 0 && (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        height: "86%",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                        }}
                      >
                        <div className="chatDetails" ref={chatScreen}>
                          {isLoadingMessages && (
                            <div
                              style={{
                                position: "absolute",
                                top: "4%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                zIndex: "999",
                              }}
                            >
                              <CircularProgress size={24} />
                            </div>
                          )}
                          {messageList.length === 0 && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                minHeight: "500px",
                                textAlign: "center",
                                color:
                                  currentTheme === "DARK" ? "#fff" : "#000",
                              }}
                            >
                              {messageList.length === 0
                                ? "There are currently no messages in this topic. Feel free to start the conversation!"
                                : ""}
                            </div>
                          )}

                          {messageList?.map((data, i) => {
                            const isSentByUser = data.from === userId;
                            return (
                              <div
                                key={i}
                                className={`chatBubble ${
                                  isSentByUser
                                    ? "chatBubbleSelf"
                                    : "chatBubbleOther"
                                }`}
                              >
                                <div>
                                  <span
                                    style={{
                                      color: "#c25208",
                                      fontWeight: "bold",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {data?.user_unique_id}
                                  </span>
                                </div>
                                <div>
                                  {data?.message}
                                </div>
                                <span className="bubbleChatDate">
                                  {data?.createdAt
                                    ? dayjs(data?.createdAt)?.format("lll")
                                    : "NA"}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div
                        className="chatKeyboard"
                        style={{
                          background: currentTheme == "DARK" ? "#000" : "#fff",
                        }}
                      >
                        <input
                          type="file"
                          id="fileInput"
                          style={{ display: "none" }}
                          onChange={(e) => handleFileSelect(e.target.files)}
                        />
                        {/* <AttachFile
                onClick={() => document.getElementById("fileInput").click()}
                style={{
                  color: "#909090",
                  margin: "0 25px 0 25px",
                  rotate: "30deg",
                  color: currentTheme === "DARK" ? "#fff" : "#909090",
                  cursor: "pointer",
                }}
              /> */}
                        {/* <PhotoCamera
                style={{
                  color: "#909090",
                  margin: "0 23px 0 0",
                  color: currentTheme === "DARK" ? "#fff" : "#909090",
                  cursor: "pointer",
                }}
                onClick={() => setShowWebcam(true)}
              />

              {showWebcam && (
                <div>
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width={"100%"}
                    height={"100%"}
                  />
                  <Button
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleCapturePhoto();
                    }}
                  >
                    Capture
                  </Button>
                </div>
              )} */}

                        {/* {capturedImage && <img src={capturedImage} alt="Captured" />} */}

                        <div
                          className="keypad"
                          style={{
                            background:
                              currentTheme == "DARK" ? "#000" : "#fff",
                          }}
                        >
                          <input
                            placeholder="Type a message..."
                            value={message}
                            style={{
                              color: currentTheme == "DARK" ? "#fff" : "#000",
                            }}
                            onChange={(e) => {
                              setMessage(e?.target?.value);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter" && message.trim()) {
                                sendMessageHandler();
                              }
                            }}
                          />
                          <KeyboardVoice
                            onClick={handleVoiceInput}
                            className={isListening ? "listening" : ""}
                            style={{
                              margin: "0 25px 0 0",
                              color:
                                currentTheme == "DARK" ? "#fff" : "#909090",
                              cursor: "pointer",
                            }}
                          />
                          {/* <VoiceInputButton onVoiceInput={handleVoiceInput} /> */}
                          <SentimentSatisfiedAlt
                            style={{
                              color:
                                currentTheme === "DARK" ? "#fff" : "#909090",
                              margin: "0 29px 0 0",
                              cursor: "pointer",
                            }}
                            onClick={toggleEmojiPicker}
                          />
                          <div>
                            <IconButton
                              onClick={sendMessageHandler}
                              // disabled={!message}
                              disabled={!message.trim()}
                            >
                              <Send />
                            </IconButton>
                          </div>
                        </div>
                        {showEmojiPicker && (
                          <EmojiPicker
                            style={{
                              position: " absolute",
                              bottom: "83px",
                              left: "220px",
                            }}
                            onEmojiClick={handleEmojiClick}
                            disableSearchBar
                            disableSkinTonePicker
                            groupVisibility={{ recently_used: false }}
                          />
                        )}
                      </div>
                    </Box>
                  )}
                </>
              )}
            </div>
            <div
              className="stasutScreen"
              style={{ background: currentTheme == "DARK" ? "#000" : "#fff" }}
            >
              <Box
                className="header"
                component={OverlayScrollbarsComponent}
                style={{
                  background: currentTheme == "DARK" ? "#000" : "#fff",
                  overflow: "scroll",
                  height: "60vh",
                }}
              >
                <div
                  className="listing"
                  style={{
                    background: currentTheme == "DARK" ? "#151515" : "#fff",
                    marginTop: "10px",
                  }}
                >
                  <h4
                    style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                  >{`${BOList.length} Participants`}</h4>
                  {BOList &&
                    BOList?.length > 0 &&
                    BOList.map((topic, i) => (
                      <div
                        key={i}
                        onClick={() => {
                          // setCurrentChat(i);
                          setCurrentBO(id);
                        }}
                        style={{
                          height: "65px",
                          // background: i % 2 === 0 ? "#FFFFF" : "#FAFAFA",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          style={{
                            background:
                              currentTheme == "DARK" ? "#151515" : "#fff",
                            background:
                              currentTheme == "LIGHT" &&
                              currentChat === i &&
                              "#fff",
                            color: currentTheme == "DARK" ? "#fff" : "#000",
                          }}
                        >
                          B
                        </span>

                        {topic?.user_unique_id ? (
                          <p
                            style={{
                              color: currentTheme == "DARK" ? "#fff" : "#000",
                            }}
                          >{`${topic.user_unique_id.slice(0, 5)}`}</p>
                        ) : (
                          <p>NA</p>
                        )}
                        <div
                          style={{
                            border: "1px solid #C9C9C9",
                            borderRadius: "50%",
                            background: topic?.userOnlineFlag
                              ? "#33F247"
                              : "transparent",
                            width: "10px",
                            height: "20px",
                          }}
                        ></div>
                      </div>
                    ))}
                </div>
              </Box>
            </div>
          </div>

          <ScheduleCallPopUp
            setOpen={setScheduleModal}
            open={scheduleModal}
            isCallListUpdated={updateScheduleCallList}
            setIsCallListUpdated={setUpdateScheduleCallList}
          />
        </div>
      </Container>
    </Box>
  );
}
