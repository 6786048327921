import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Stack,
  Paper,
  Container,
} from "@mui/material";
import { styled } from "@mui/system";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { ApiConfig } from "../../services/ApiConfig";
import { useNavigate } from "react-router";
import customAxios from "../../utils/customAxios";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Welcome = () => {
  const [userData, setUserData] = useState([]);
  const [cohortName, setCohortName] = useState("");
  const navigate = useNavigate();
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);

  const getDetails = async () => {
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig?.getUserWithAdvisorDetails,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
      });
      if (res.data?.statusCode === 200) {
        console.log(res.data.result);
        setUserData([res.data.result]);
        setCohortName(res?.data?.result?.cohort?.cohortName);
        sessionStorage.setItem(
          "advisorId",
          res.data.result.business_adviser_id
        );
        sessionStorage.setItem("cohortId", res?.data?.result?.cohort?.id);
        sessionStorage.setItem("ownerName", res.data.result.first_name);
        sessionStorage.setItem("ownerFullName", res.data.result.full_name);
        sessionStorage.setItem(
          "advisorName",
          res.data.result.assignedAdvisorDetails?.full_name
        );
        if (res.data.result.assignedAdvisorDetails?.full_name) {
          sessionStorage.setItem("advisorAssigned", "YES");
        } else {
          sessionStorage.setItem("advisorAssigned", "NO");
        }
      } else {
        console.log(
          res.data?.responseMessage || "Something went wrong. Please try again."
        );

        return null;
      }
    } catch (error) {
      console.log(
        error?.response?.data?.responseMessage ||
          "Something went wrong. Please try again."
      );
    }
  };

  useEffect(() => {
    getDetails();
  }, []);
  return (
    <Box
      // px={20}
      py={8}
      position="relative"
      sx={{
        background:
          currentTheme == "DARK"
            ? "#151515"
            : 'url("/images/dashbackground.png")',
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        // paddingX: { xl: "130px", md: "70px", sm: "100px", xs: "20px" },
      }}
    >
      {/* <Box
        component="img"
        position={{ lg: "absolute" }}
        zIndex={-1}
        src="/images/dashbackground.png"
        sx={{
          width: { lg: "90vw", sm: "60vw", xs: "50vw" },
          height: { lg: "70vh", sm: "60vh", xs: "50vh" },

          display: { lg: "block", xs: "none" },
        }}
      /> */}
      <Container maxWidth="lg">
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginY: { xs: "20px", lg: "0px" },
            }}
          >
            <Typography
              // mt={4}
              sx={{
                textAlign: "center",
                fontSize: { lg: "40px", xs: "22px", md: "30px" },

                background:
                  "var(--Linear, linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%))",
                backgroundClip: "text",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
              fontWeight="700"
            >
              Welcome!
            </Typography>
            <Typography
              mt={1}
              style={{
                textAlign: "center",
                fontSize: { lg: "30px", xs: "18px", md: "20px" },
                color: currentTheme === "DARK" ? "#fff" : "#000",
              }}
            >
              Business Boost Accelerator
            </Typography>
            <Typography
              color={currentTheme === "DARK" ? "#fff" : "#000"}
              mt={2}
              sx={{ textAlign: "center", fontSize: "20px" }}
            >
              {cohortName}
            </Typography>
            <Box>
              <Box
                component="img"
                src={
                  currentTheme === "DARK"
                    ? "/images/logo dark theme.png"
                    : "/images/boostlogo2.png"
                }
                className="company-img"
                sx={{
                  width: "150px",
                  // height: "50px",
                  marginTop: "30px",
                  marginX: { md: "65px", xs: "40px" },
                }}
              />
            </Box>
          </Box>
        </Box>
        {userData.map((val, idx) => (
          <Grid
            container
            sx={{
              justifyContent: {
                lg: "space-between",
                sm: "center",
                xs: "center",
              },
              gap: {
                sm: "20px",
                xs: "20px",
              },
              // paddingX: { xs: "none", sm: "60px", lg: "none" },
            }}
          >
            <Grid item>
              <Paper
                sx={{
                  width: { lg: "336px", xs: "250px" },
                  height: { lg: "365px", xs: "250px" },
                  marginY: { xs: "20px", lg: "0px" },
                  paddingTop: "40px",
                  borderRadius: "20px",
                  boxShadow: "0px -1px 17px 0px rgba(0, 0, 0, 0.15)",
                  cursor: "pointer",
                  backgroundColor: currentTheme == "DARK" ? "#242424" : "#fff",
                }}
                onClick={() => navigate("/my-profile")}
              >
                <Box
                  sx={{
                    marginLeft: "80px",
                    borderRadius: "50%",
                    width: { lg: "180px", xs: "100px" },
                    height: { lg: "180px", xs: "100px" },
                    objectFit: "cover",
                    border: "6px solid #E7E7E7",
                  }}
                  component="img"
                  src={val.image_url ? val.image_url : "/images/avatar.png"}
                />
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: { lg: "30px", xs: "20px" },
                  }}
                  variant="h4"
                >
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      padding: "0px 10px",
                      color: currentTheme == "DARK" ? "#fff" : "#000",
                    }}
                  >
                    {" "}
                    {val?.full_name}
                  </div>
                </Typography>
                <Typography sx={{ textAlign: "center" }} variant="body1">
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      padding: "0px 10px",
                      color: currentTheme == "DARK" ? "#a3a3a3" : "#000",
                    }}
                  >
                    {val?.title}
                  </div>
                </Typography>
                <Typography
                  sx={{ textAlign: "center", fontWeight: 500 }}
                  variant="body1"
                >
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      padding: "0px 10px",
                      color: currentTheme == "DARK" ? "#a3a3a3" : "#000",
                    }}
                  >
                    {val?.business_name}
                  </div>
                </Typography>
              </Paper>
            </Grid>
            <Grid
              item
              justifyContent="center"
              alignItems="center"
              sx={{ display: { xs: "none", md: "block" } }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  marginY: { xs: "20px", lg: "0px" },
                  width: "400px", // Fixed width
                  padding: "20px", // Fixed padding
                }}
              >
                <Typography
                  mt={3}
                  sx={{
                    textAlign: "center",
                    fontSize: { lg: "40px", xs: "22px", md: "30px" },
                    background:
                      "var(--Linear, linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%))",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                  fontWeight="700"
                >
                  Welcome!
                </Typography>
                {/*<Typography
                  mt={0}
                  sx={{
                    textAlign: "center",
                    fontSize: { lg: "30px", xs: "18px", md: "20px" },
                    color: currentTheme == "DARK" ? "white" : "black",
                  }}
                >
                  Business Boost Accelerator
                </Typography>*/}
                <Typography
                  mt={2}
                  className="wordBreak"
                  sx={{
                    marginTop: "50px",
                    textAlign: "center",
                    fontSize: "35px",
                    color: currentTheme == "DARK" ? "white" : "black",
                    fontWeight: "bold",
                  }}
                >
                  {val?.cohort?.cohortName}
                </Typography>
                {/*<Box>
                  <Box
                    component="img"
                    src={
                      currentTheme === "DARK"
                        ? "/images/boostlogo2.png"
                        : "/images/boostlogo2.png"
                    }
                    className="company-img"
                    sx={{
                      width: "150px",
                      // height: "50px",
                      marginTop: "30px",
                      marginX: { md: "107px", xs: "-10px" },
                    }}
                  />
                </Box>*/}
              </Box>
            </Grid>
            <Grid item>
              <Paper
                sx={{
                  width: { lg: "336px", xs: "250px" },
                  height: { lg: "365px", xs: "250px" },
                  marginY: { xs: "20px", lg: "0px" },
                  paddingTop: "40px",
                  borderRadius: "20px",
                  boxShadow: "0px -1px 17px 0px rgba(0, 0, 0, 0.15)",
                  backgroundColor: currentTheme == "DARK" ? "#242424" : "#fff",
                }}
              >
                <Box
                  sx={{
                    marginLeft: "80px",
                    borderRadius: "50%",
                    border: "6px solid #E7E7E7",
                    width: { lg: "180px", xs: "100px" },
                    height: { lg: "180px", xs: "100px" },
                    objectFit: "cover",
                  }}
                  component="img"
                  src={
                    val?.assignedAdvisorDetails?.image_url
                      ? val?.assignedAdvisorDetails?.image_url
                      : "/images/avatar.png"
                  }
                />
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: { lg: "30px", xs: "20px" },
                  }}
                  variant="h4"
                >
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      padding: "0px 10px",
                      color: currentTheme == "DARK" ? "#fff" : "#000",
                    }}
                  >
                    {val.assignedAdvisorDetails
                      ? val.assignedAdvisorDetails.full_name
                      : "Pending"}
                  </div>
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: currentTheme == "DARK" ? "#a3a3a3" : "#000",
                  }}
                  variant="body1"
                >
                  Strategic Advisor
                </Typography>
                <Box display="flex">
                  {" "}
                  <Button
                    disabled={sessionStorage.getItem("advisorAssigned") == "NO"}
                    sx={{
                      background:
                        "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                      marginX: "70px",
                      mt: 1,
                      fontSize: "16px",
                      textTransform: "none",
                      borderRadius: "10px",
                      width: { sm: "300px" },
                    }}
                    onClick={(e) => {
                      e.preventDefault();

                      // userApplyForCohort(item?.cohort?.id)
                      // handleOpenLogoutModal()
                      // navigate(`/guest-community/${item?.cohort?.id}`);
                      navigate(`/advisor-chat`, {
                        state: {
                          advId: val?.assignedAdvisorDetails?.id,
                          advName: val?.assignedAdvisorDetails?.full_name,
                          image_url: val?.assignedAdvisorDetails?.image_url,
                        },
                      });
                    }}
                    variant="contained"
                  >
                    Chat with Advisor
                  </Button>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        ))}
      </Container>
    </Box>
  );
};

export default Welcome;
