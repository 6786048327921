import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

import "./findingFunders.scss";
import { useSelector } from "react-redux";
import { ApiConfig } from "../../services/ApiConfig";
import { apiWithToken } from "../../services/apiWithToken/apiWithToken";

export default function FindingFunders() {
  const navigate = useNavigate();

  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  const [savedFunderList, setSavedFunderList] = useState([]);
  const [showSave, setShowSave] = useState(true);

  const getSavedFunders = async () => {
    const options = {
      url: ApiConfig.getSavedFunderList,
      method: "GET",
      params: {
        userid: sessionStorage.getItem("userId"),
      },
    };
    // setIsSavedFunderListLoading(true);
    const res = await apiWithToken(options);
    // setIsSavedFunderListLoading(false);
    if (res?.data?.statusCode === 200) {
      console.log(res?.data?.result?.length);
      if (res?.data?.result?.length === 0) {
        setShowSave(false);
      } else {
        setShowSave(true);
      }
    }
  };

  useEffect(() => {
    getSavedFunders();
  });
  return (
    <div
      style={{ background: currentTheme == "DARK" ? "#151515" : "#fff" }}
      className="findingFundersWrapper"
     
    >
      <h1 >Business Funding Finderr</h1>
      <h2 style={{ color: currentTheme == "DARK" ? "#ffff" : "#000" }}>
        Welcome to the new and advance Funding Finder!
      </h2>
      <button
        onClick={() =>
          navigate(
            showSave
              ? "/findingFunders/fundersAssessment/fundersList"
              : "fundersAssessment",
            { state: { type: "" } }
          )
        }
        className="core-button"
      >
        Find your Funder
      </button>
      <img src="/images/funderimg1.png" className="l1" alt="" />
      <img src="/images/funderimg2.png" className="l2" alt="" />
      <img src="/images/funderimg3.png" className="r1" alt="" />
      <img src="/images/funderimg4.png" className="r2" alt="" />
    </div>
  );
}
