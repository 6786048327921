import React, { useEffect, useState } from "react";
import "./../discoveryCoaches/discoveryCoaches.css";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiConfig } from "../../services/ApiConfig";
import { toast } from "react-toastify";
import { Box, CircularProgress, Container } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import customAxios from "../../utils/customAxios";
import { Button, Typography } from "@mui/material";
export const AssessmentType = {
  ADD: "ADD",
  EDIT: "EDIT",
  VIEW: "VIEW",
};
export default function CustomFormTab() {
  const location = useLocation();
  console.log("location:  in CustomFormTab", location.state);
  const cohortId = location.state?.cohortId;
  const cohortName = location.state?.cohortName;
  const categoryId = location.state?.categoryId;
  const customFormCategoryTitle = location.state?.customFormCategoryTitle;
  const fullName = location.state?.fullName;
  const [percentageData, setPercentageData] = useState([]);
  console.log("percentageData: ", percentageData);
  const [userDetails, setUserDetails] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [customFormData, setCustomFormData] = useState([]);

  const getAllCustomForm = async () => {
    try {
      setIsDataLoading(true);
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.getAllCustomForms,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        params: {
          user_id: sessionStorage.getItem("userId"),
          cohort_id: cohortId,
          category_id: categoryId,
          page: currentPage,
          limit: rowsPerPage,
        },
      });

      if (res?.data?.statusCode === 200) {
        setCustomFormData(res?.data?.result?.customForms || []);
        setPercentageData(
          res?.data?.result?.customForms?.answerPercentage || []
        );
        setTotalQuestions(res?.data?.result?.totalPages || 0);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsDataLoading(false);
    }
  };

  useEffect(() => {
    getAllCustomForm();
  }, []);

  const evaluatePercentage = (percentage) => {
    if (percentage > 99) {
      return 100;
    } else if (percentage === 0 || percentage === null) {
      return 0;
    } else {
      return percentage.toFixed(0);
    }
  };

  const evaluateDataToFilter = (id, i) => {
    const foundElement = percentageData.filter((element) => {
      if (element?.assesmentMasterId === id) {
        return element;
      }
    });

    if (foundElement[0]?.version_control_count?.version_control == 0) {
      return false;
    }
    return true;
  };

  return (
    <div
      style={{
        backgroundColor: currentTheme === "DARK" ? "#151515" : "#fff",
      }}
    >
      {" "}
      <Container maxWidth="xl">
        <div className="discoveryWrapper">
          <div className="header">
            <h2 style={{ color: currentTheme === "DARK" ? "#fff" : "#000" }}>
              {customFormCategoryTitle || "Custom Forms"}
            </h2>
          </div>
          {isDataLoading ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "43vh",
              }}
            >
              <CircularProgress
                sx={{
                  color: "#523366",
                }}
              />
            </div>
          ) : customFormData.filter((item) => item.is_active).length === 0 ? (
            <div
              style={{
                margin: "auto",
                fontSize: "20px",
                textAlign: "center",
                fontWeight: "600",
                color: currentTheme === "DARK" ? "#fff" : "#000",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "50%",
                }}
              >
                <img src="/images/nodata.png" alt="logo" />
              </Box>
              {userDetails.cohort_id === null ||
              userDetails.cohort_id === "null"
                ? "No cohort assigned yet"
                : "No Custom form found for this cohort !"}
            </div>
          ) : (
            //   )}
            <div className="cards">
              {customFormData.length > 0 &&
                customFormData
                  .filter((item) => {
                    return item?.is_active;
                  })
                  .map((item, i) => {
                    const answerPercentage = evaluatePercentage(
                      item.answerPercentage
                    );
                    return (
                      <div
                        style={{
                          backgroundColor:
                            currentTheme === "DARK" ? "#000" : "#fff",
                        }}
                        className="card"
                      >
                        <div className="inner1">
                          <span></span>
                          <p
                            style={{
                              color: currentTheme === "DARK" ? "#FFF" : "#000",
                            }}
                          >
                            {item?.formTitle}
                          </p>
                        </div>
                        <div className="inner2">
                          <div>
                            <span
                              style={{
                                width: `${answerPercentage}%`,
                              }}
                            ></span>
                          </div>
                          {answerPercentage === 0 && (
                            <p
                              style={{
                                color:
                                  currentTheme === "DARK" ? "#FFF" : "#000",
                              }}
                            >
                              Your form has not been started yet.
                            </p>
                          )}
                          {answerPercentage > 0 && answerPercentage < 100 && (
                            <p
                              style={{
                                color:
                                  currentTheme === "DARK" ? "#FFF" : "#000",
                              }}
                            >{`Your form is ${answerPercentage}% completed.`}</p>
                          )}
                          {answerPercentage === 100 && (
                            <p
                              style={{
                                color:
                                  currentTheme === "DARK" ? "#FFF" : "#000",
                              }}
                            >
                              Your form is 100% completed.
                            </p>
                          )}
                        </div>
                        <div className="inner3">
                          {answerPercentage === 100 && (
                            <>
                              <button
                                style={{
                                  color:
                                    currentTheme === "DARK" ? "#FFF" : "#000",
                                  background: "transparent",
                                  borderColor: "#2b1053",
                                }}
                                onClick={() => {
                                  navigate(`/createCustomForm`, {
                                    state: {
                                      type: AssessmentType.VIEW,
                                      cohortId: cohortId,
                                      cohortName: cohortName,
                                      questionCategoryId: item?.category_id,
                                      customFormTitle: item?.formTitle,
                                      customFormLogo: item?.logoUrl,
                                      customFormBgLogo: item?.backgroundLogo,
                                      customFormId: item?.id,
                                      cohortIntroduction: item?.introduction,
                                      cohortThanksMessage: item?.thanksMessage,
                                    },
                                  });
                                }}
                              >
                                View
                              </button>
                              {/* Update Now button */}
                              <button
                                onClick={() => {
                                  navigate("/createCustomForm", {
                                    state: {
                                      cohortId: cohortId,
                                      cohortName: cohortName,
                                      customFormTitle: item?.formTitle,
                                      questionCategoryId: item?.category_id,
                                      customFormLogo: item?.logoUrl,
                                      customFormBgLogo: item?.backgroundLogo,
                                      customFormId: item?.id,
                                      cohortIntroduction: item?.introduction,
                                      cohortThanksMessage: item?.thanksMessage,
                                    },
                                  });
                                }}
                                style={{
                                  width: "100%",
                                  margin: "auto",
                                }}
                              >
                                Update Now
                              </button>
                            </>
                          )}
                          {answerPercentage === 0 && (
                            <button
                              onClick={() => {
                                navigate(`/createCustomForm`, {
                                  state: {
                                    cohortId: cohortId,
                                    cohortName: cohortName,
                                    customFormTitle: item?.formTitle,
                                    questionCategoryId: item?.category_id,
                                    customFormLogo: item?.logoUrl,
                                    customFormBgLogo: item?.backgroundLogo,
                                    customFormId: item?.id,
                                    cohortIntroduction: item?.introduction,
                                    cohortThanksMessage: item?.thanksMessage,
                                  },
                                });
                              }}
                              style={{
                                width: !evaluateDataToFilter(item?.id, i)
                                  ? "57%"
                                  : "100%",
                                margin: "auto",
                              }}
                            >
                              Create Now
                            </button>
                          )}
                          {answerPercentage > 0 && answerPercentage < 100 && (
                            <button
                              onClick={() => {
                                navigate(`/createCustomForm`, {
                                  state: {
                                    cohortId,
                                    cohortName,
                                    questionCategoryId: item?.category_id,
                                    customFormTitle: item?.formTitle,
                                    customFormLogo: item?.logoUrl,
                                    customFormBgLogo: item?.backgroundLogo,
                                    customFormId: item?.id,
                                    cohortIntroduction: item?.introduction,
                                    cohortThanksMessage: item?.thanksMessage,
                                  },
                                });
                              }}
                              style={{
                                width: "100%",
                                margin: "auto",
                              }}
                            >
                              Update Now
                            </button>
                          )}
                        </div>
                      </div>
                    );
                  })}
              <div></div>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
}
